import {
  CollectionReference,
  collection,
  orderBy,
  limit,
  startAfter,
  query,
  getDocs,
  QuerySnapshot,
  DocumentSnapshot,
  doc,
  getDoc,
  deleteDoc,
  addDoc,
  setDoc,
} from "firebase/firestore";
import { db } from "../main";

export const gradeData = [
  { key: "1", value: "1年" },
  { key: "2", value: "2年" },
  { key: "3", value: "3年" },
  { key: "4", value: "4年" },
  { key: "5", value: "修士1年" },
  { key: "6", value: "修士2年" },
  { key: "7", value: "その他" },
];

export const studentFacultyData = [
  { key: "1", value: "政治経済学部" },
  { key: "2", value: "法学部" },
  { key: "3", value: "教育学部" },
  { key: "4", value: "商学部" },
  { key: "5", value: "社会科学部" },
  { key: "6", value: "人間科学部" },
  { key: "7", value: "スポーツ科学部" },
  { key: "8", value: "国際教養学部" },
  { key: "9", value: "文化構想学部" },
  { key: "10", value: "文学部" },
  { key: "11", value: "人間科学部(通信教育課程）" },
  { key: "12", value: "基幹理工学部" },
  { key: "13", value: "創造理工学部" },
  { key: "14", value: "先進理工学部" },
  { key: "15", value: "大学院政治学研究科" },
  { key: "16", value: "大学院経済学研究科" },
  { key: "17", value: "大学院法学研究科" },
  { key: "18", value: "大学院文学研究科" },
  { key: "19", value: "大学院商学研究科" },
  { key: "20", value: "大学院教育学研究科" },
  { key: "21", value: "大学院人間科学研究科" },
  { key: "22", value: "大学院社会科学研究科" },
  { key: "23", value: "大学院アジア太平洋研究科" },
  { key: "24", value: "大学院日本語教育研究科" },
  { key: "25", value: "大学院情報生産システム研究科" },
  { key: "26", value: "大学院法務研究科" },
  { key: "27", value: "大学院会計研究科" },
  { key: "28", value: "大学院スポーツ科学研究科" },
  { key: "29", value: "大学院基幹理工学研究科" },
  { key: "30", value: "大学院創造理工学研究科" },
  { key: "31", value: "大学院先進理工学研究科" },
  { key: "32", value: "大学院環境・エネルギー研究科" },
  { key: "33", value: "大学院国際コミュニケーション研究科" },
  { key: "34", value: "大学院経営管理研究科" },
  { key: "35", value: "芸術学校" },
  { key: "36", value: "日本語教育研究センター" },
  { key: "37", value: "留学センター" },
];

// 学生情報を表示する関数
export async function displayStudentInfos() {
  // サークル情報のcollectionへのreference
  const dbRef: CollectionReference = collection(
    db,
    "jobHuntingStudentUsersPublic"
  );
  // 先頭10件を表示するクエリを作成
  const q = query(dbRef, orderBy("updatedAt"), limit(10));
  const querySnapshot = await getDocs(q);
  const response: any = [];
  // 以下取得したデータを整形して返す
  const addData = async (data: any, id: string) => {
    const docData = {
      iconImage: data.iconPhtUrl,
      senderId: id,
      grade: data.grade,
      university: data.university,
      faculty: data.faculty,
      department: data.department,
      firstName: data.firstName,
      lastName: data.lastName,
      firstNameKana: data.firstNameKana,
      lastNameKana: data.lastNameKana,
      sex: data.sex,
      livingArea: data.livingArea,
      birthPlace: data.birthPlace,
      graduatedHighSchool: data.graduatedHighSchool,
      certification: data.certification,
      programmingExperience: data.programmingExperience,
      programmingExperienceDetail: data.programmingExperienceDetail,
      circle: data.circle,
      researchGroup: data.researchGroup,
      desiredFirstBusiness: data.desiredFirstBusiness,
      desiredSecondBusiness: data.desiredSecondBusiness,
      desiredThirdBusiness: data.desiredThirdBusiness,
      desiredFirstJob: data.desiredFirstJob,
      desiredSecondJob: data.desiredSecondJob,
      desiredThirdJob: data.desiredThirdJob,
      choiceFirstStandard: data.choiceFirstStandard,
      choiceSecondStandard: data.choiceSecondStandard,
      choiceThirdStandard: data.choiceThirdStandard,
      graduatedYear: data.graduatedYear,
      graduatedMonth: data.graduatedMonth,
      humanitiesOrScience: data.humanitiesOrScience,
      internCompName: data.internCompName,
      internJobType: data.internJobType,
      internPeriod: data.internPeriod,
      selfPRMain: data.selfPRMain,
      updatedAt: data.updatedAt.toDate(),
    };
    response.push(docData);
  };

  const dataDivisor: any = async (snap: QuerySnapshot) => {
    for (const doc of snap.docs) {
      await addData(doc.data(), doc.id);
    }
    return [response, snap.docs[9]];
  };
  return await dataDivisor(querySnapshot);
}

// 学生情報を表示する関数（現在は使われていない。）
export async function displayExtraStudentInfos(lastSnap: DocumentSnapshot) {
  const dbRef: CollectionReference = collection(
    db,
    "jobHuntingStudentUsersPublic"
  );
  const q = query(
    dbRef,
    orderBy("updatedAt"),
    startAfter(lastSnap.data()!.updatedAt),
    limit(10)
  );
  const querySnapshot = await getDocs(q);
  const response: any = [];
  const addData = async (data: any, id: string) => {
    const docData = {
      iconImage: data.iconPhtUrl,
      senderId: id,
      grade: data.grade,
      university: data.university,
      faculty: data.faculty,
      department: data.department,
      firstName: data.firstName,
      lastName: data.lastName,
      firstNameKana: data.firstNameKana,
      lastNameKana: data.lastNameKana,
      sex: data.sex,
      livingArea: data.livingArea,
      birthPlace: data.birthPlace,
      graduatedHighSchool: data.graduatedHighSchool,
      certification: data.certification,
      programmingExperience: data.programmingExperience,
      programmingExperienceDetail: data.programmingExperienceDetail,
      circle: data.circle,
      researchGroup: data.researchGroup,
      desiredFirstBusiness: data.desiredFirstBusiness,
      desiredSecondBusiness: data.desiredSecondBusiness,
      desiredThirdBusiness: data.desiredThirdBusiness,
      desiredFirstJob: data.desiredFirstJob,
      desiredSecondJob: data.desiredSecondJob,
      desiredThirdJob: data.desiredThirdJob,
      choiceFirstStandard: data.choiceFirstStandard,
      choiceSecondStandard: data.choiceSecondStandard,
      choiceThirdStandard: data.choiceThirdStandard,
      graduatedYear: data.graduatedYear,
      graduatedMonth: data.graduatedMonth,
      humanitiesOrScience: data.humanitiesOrScience,
      internCompName: data.internCompName,
      internJobType: data.internJobType,
      internPeriod: data.internPeriod,
      selfPRMain: data.selfPRMain,
      updatedAt: data.updatedAt.toDate(),
    };
    response.push(docData);
  };

  const dataDivisor: any = async (snap: QuerySnapshot) => {
    for (const doc of snap.docs) {
      await addData(doc.data(), doc.id);
    }
    return [response, snap.docs[9]];
  };
  return await dataDivisor(querySnapshot);
}

//お気に入りの生徒を登録する関数
export async function setFavoriteStudent(studentId: string, uid: string) {
  const companyRef = doc(db, "compUsersPrivate", uid);
  const companyDocSnapshot = await getDoc(companyRef);
  if (companyDocSnapshot.exists()) {
    const studentRef = doc(db, "jobHuntingStudentUsersPublic", studentId);
    const student = await getDoc(studentRef);
    if (student.exists()) {
      const favStudentRef = doc(
        db,
        "compusersPrivate",
        uid,
        "favStudents",
        studentId
      );
      const favStudent = await getDoc(favStudentRef);
      //学生がお気に入り登録されていれば削除
      if (favStudent.exists()) {
        deleteDoc(favStudentRef);
      }
      //学生がお気に入り登録されていなければ登録
      else {
        const studentData = student.data();
        setDoc(favStudentRef, {
          name: studentData.lastName + " " + studentData.firstName,
          iconPhtUrl: studentData.iconPhtUrl,
          university: studentData.university,
          graduatedYear: studentData.graduatedYear,
        });
      }
    }
  }
}
