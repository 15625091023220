export const oemUniversityData = [
  { key: "1", value: "早稲田大学" },
  { key: "2", value: "青山学院大学" },
  { key: "3", value: "立教大学" },
  { key: "4", value: "中央大学" },
  { key: "5", value: "法政大学" },
  { key: "6", value: "上智大学" },
  { key: "7", value: "京都大学" },
  { key: "8", value: "大阪大学" },
  { key: "9", value: "立命館大学" },
  { key: "10", value: "同志社大学" },
  { key: "11", value: "関西大学" },
  { key: "12", value: "関西学院大学" },
  { key: "13", value: "明治大学" },
  { key: "14", value: "東京大学" },
  { key: "15", value: "名古屋大学" },
  { key: "16", value: "九州大学" },
];
