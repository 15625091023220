import {
  DocumentData,
  DocumentSnapshot,
  FieldValue,
  QueryDocumentSnapshot,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  startAfter,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";
import { db, functions, storage } from "../main";
import { StorageReference, ref, uploadBytes } from "firebase/storage";
import { oemCoupons } from "types/firebase/oem";
import { httpsCallable } from "firebase/functions";
import { oemUniversityEnglishData } from "context/OEMinfo";
import { unparse } from "papaparse";
import { studentFacultyData } from "./studentInfo";
import {
  agutimesDepartmentData,
  dutsDepartmentData,
  gradeData,
  handyDepartmentData,
  kulifeStudentFucltyData,
  meidaiDepartmentData,
  kyudaiDepartmentData,
  ritsmateDepartmentData,
  sophiaDepartmentData,
  ticDepartmentData,
  wasedaStudentFacultyData,
} from "data/StudentProfileData";

// サークルのリストを作成する関数
export async function getCircleList(
  uid: string,
  university: number
): Promise<{
  errMsg: string;
  circleList: any[];
  lastVisible: any;
  totalCount: number;
}> {
  let errMsg = "";
  const superuserDoc = doc(db, "OEMsuperuser", uid);
  const superuserSnapshot = await getDoc(superuserDoc);
  if (
    superuserSnapshot.exists() &&
    superuserSnapshot.data().university.includes(university)
  ) {
    const totalQ = query(
      collection(db, "clubUsersPrivate"),
      where("university", "==", university),
      orderBy("updatedAt", "desc")
    );
    const q = query(
      collection(db, "clubUsersPrivate"),
      where("university", "==", university),
      orderBy("updatedAt", "desc"),
      limit(10)
    );
    const totalCount = await getCountFromServer(totalQ);
    const circleSnapshot = await getDocs(q);
    const circleList = circleSnapshot.docs.map((doc) => ({
      ...doc.data(),
    }));

    const lastVisible = circleSnapshot.docs[circleSnapshot.docs.length - 1];
    return {
      errMsg,
      circleList,
      lastVisible,
      totalCount: totalCount.data().count,
    };
  } else {
    errMsg = "適切な権限がありません。";
    return { errMsg, circleList: [], lastVisible: "", totalCount: 0 };
  }
}

//追加でサークルを取得する関数（最後のドキュメントスナップショットを渡す）
export async function getAdditionalCircleList(
  uid: string,
  university: number,
  lastDocSnapshot: any
): Promise<{ errMsg: string; circleList: any[]; lastVisible: any }> {
  let errMsg = "";
  const superuserDoc = doc(db, "OEMsuperuser", uid);
  const superuserSnapshot = await getDoc(superuserDoc);
  //適切な権限があるか確認
  if (
    superuserSnapshot.exists() &&
    superuserSnapshot.data().university.includes(university)
  ) {
    const nextQuery = query(
      collection(db, "clubUsersPrivate"),
      where("university", "==", university),
      orderBy("updatedAt", "desc"),
      startAfter(lastDocSnapshot),
      limit(10)
    );
    const circleSnapshot = await getDocs(nextQuery);
    const circleList = circleSnapshot.docs.map((doc) => ({
      ...doc.data(), // すべてのフィールドを取得
    }));
    const lastVisible = circleSnapshot.docs[circleSnapshot.docs.length - 1];
    return { errMsg, circleList, lastVisible };
  } else {
    errMsg = "適切な権限がありません。";
    return { errMsg, circleList: [], lastVisible: [] };
  }
}

//登録されている企業を取得する関数
export async function getCompanyList(
  uid: string,
  university: number
): Promise<{
  errMsg: string;
  companyList: any[];
  lastVisible: any;
  totalCount: number;
}> {
  let errMsg = "";
  const superuserDoc = doc(db, "OEMsuperuser", uid);
  const superuserSnapshot = await getDoc(superuserDoc);
  if (
    superuserSnapshot.exists() &&
    superuserSnapshot.data().university.includes(university)
  ) {
    const totalQ = query(
      collection(db, "compUsersPrivate"),
      orderBy("updatedAt", "desc")
    );
    const q = query(
      collection(db, "compUsersPrivate"),
      orderBy("updatedAt", "desc"),
      limit(10)
    );
    const companySnapshot = await getDocs(q);
    const companyList = companySnapshot.docs.map((doc) => ({
      ...doc.data(), // すべてのフィールドを取得
    }));
    const totalCount = await getCountFromServer(totalQ);
    const lastVisible = companySnapshot.docs[companySnapshot.docs.length - 1];
    return {
      errMsg,
      companyList,
      lastVisible,
      totalCount: totalCount.data().count,
    };
  } else {
    errMsg = "適切な権限がありません。";
    return { errMsg, companyList: [], lastVisible: [], totalCount: 0 };
  }
}

//追加で会社を取得する関数（最後のドキュメントスナップショットを渡す）
export async function getAdditionalCompanyList(
  uid: string,
  university: number,
  lastDocSnapshot: any
): Promise<{ errMsg: string; companyList: any[]; lastVisible: any }> {
  let errMsg = "";
  const superuserDoc = doc(db, "OEMsuperuser", uid);
  const superuserSnapshot = await getDoc(superuserDoc);
  //適切な権限が存在しているか確認
  if (
    superuserSnapshot.exists() &&
    superuserSnapshot.data().university.includes(university)
  ) {
    const nextQuery = query(
      collection(db, "compUsersPrivate"),
      orderBy("updatedAt", "desc"),
      startAfter(lastDocSnapshot),
      limit(10)
    );
    const companySnapshot = await getDocs(nextQuery);
    const companyList = companySnapshot.docs.map((doc) => ({
      ...doc.data(), // すべてのフィールドを取得
    }));
    const lastVisible = companySnapshot.docs[companySnapshot.docs.length - 1];
    return { errMsg, companyList, lastVisible };
  } else {
    errMsg = "適切な権限がありません。";
    return { errMsg, companyList: [], lastVisible: [] };
  }
}

//登録している学生一覧を取得する関数
export async function getStudentList(
  uid: string,
  university: number
): Promise<{
  errMsg: string;
  studentList: any[];
  lastVisible: any;
  totalCount: number;
}> {
  let errMsg = "";
  const superuserDoc = doc(db, "OEMsuperuser", uid);
  const superuserSnapshot = await getDoc(superuserDoc);
  //適切な権限があるか確認
  if (
    superuserSnapshot.exists() &&
    superuserSnapshot.data().university.includes(university)
  ) {
    const totalQ = query(
      collection(db, "studentUsersPrivate"),
      where("university", "==", university),
      orderBy("updatedAt", "desc")
    );
    const q = query(
      collection(db, "studentUsersPrivate"),
      where("university", "==", university),
      orderBy("updatedAt", "desc"),
      limit(10)
    );
    const totalCount = await getCountFromServer(totalQ);
    const studentSnapshot = await getDocs(q);
    const studentList = studentSnapshot.docs.map((doc) => ({
      ...doc.data(),
    }));
    const lastVisible = studentSnapshot.docs[studentSnapshot.docs.length - 1];
    console.log(totalCount);

    return {
      errMsg,
      studentList,
      lastVisible,
      totalCount: totalCount.data().count,
    };
  } else {
    errMsg = "適切な権限がありません。";
    return { errMsg, studentList: [], lastVisible: [], totalCount: 0 };
  }
}
const getFacultyData = (faculty: number | undefined, university: string) => {
  const getValue = (dataArray: any[], index: number) => {
    return index >= 0 && index < dataArray.length
      ? dataArray[index].value
      : "Unknown Faculty";
  };

  if (faculty === undefined) {
    return "Unknown Faculty";
  }

  switch (university) {
    case "1":
      return getValue(studentFacultyData, faculty - 1);
    case "2":
      return getValue(agutimesDepartmentData, faculty - 1);
    case "6":
      return getValue(sophiaDepartmentData, faculty - 1);
    case "7":
      return getValue(kulifeStudentFucltyData, faculty - 1);
    case "8":
      return getValue(handyDepartmentData, faculty - 1);
    case "9":
      return getValue(ritsmateDepartmentData, faculty - 1);
    case "10":
      return getValue(dutsDepartmentData, faculty - 1);
    case "11":
      return getValue(dutsDepartmentData, faculty - 1);
    case "12":
      return getValue(dutsDepartmentData, faculty - 1);
    case "14":
      return getValue(ticDepartmentData, faculty - 1);
    case "15":
      return getValue(meidaiDepartmentData, faculty - 1);
    case "16":
      return getValue(kyudaiDepartmentData, faculty - 1);
    default:
      return "Unknown Faculty";
  }
};
export async function getStudentListCSV(
  uid: string,
  university: number
): Promise<{
  errMsg: string;
}> {
  let errMsg = "";
  const superuserDoc = doc(db, "OEMsuperuser", uid);
  const superuserSnapshot = await getDoc(superuserDoc);
  //適切な権限があるか確認
  if (
    superuserSnapshot.exists() &&
    superuserSnapshot.data().university.includes(university)
  ) {
    const q = query(
      collection(db, "studentUsersPrivate"),
      where("university", "==", university),
      orderBy("updatedAt", "desc")
    );
    const studentSnapshot = await getDocs(q);
    const studentList = studentSnapshot.docs.map((doc) => ({
      ...doc.data(),
      faculty: getFacultyData(doc.data().faculty, university.toString()),
      grade: gradeData[doc.data().grade - 1].value,
    }));
    // Define the order of columns
    const columns = ["name", "address", "faculty", "grade"]; // Replace with actual field names

    // Convert studentList to CSV with specified column order
    const csv = unparse(studentList, { header: true, columns });

    // Create a Blob from the CSV string
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);

    // Create a link element and trigger a download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "studentList.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    return {
      errMsg,
    };
  } else {
    errMsg = "適切な権限がありません。";
    return { errMsg };
  }
}
//追加で学生を取得する関数（最後のドキュメントスナップショットを渡す）
export async function getAdditionalStudentList(
  uid: string,
  university: number,
  lastDocSnapshot: any
): Promise<{ errMsg: string; companyList: any[]; lastVisible: any }> {
  let errMsg = "";
  const superuserDoc = doc(db, "OEMsuperuser", uid);
  const superuserSnapshot = await getDoc(superuserDoc);
  // 適切な権限があるか確認
  if (
    superuserSnapshot.exists() &&
    superuserSnapshot.data().university.includes(university)
  ) {
    const nextQuery = query(
      collection(db, "studentUsersPrivate"),
      where("university", "==", university),
      orderBy("updatedAt", "desc"),
      startAfter(lastDocSnapshot),
      limit(10)
    );
    const companySnapshot = await getDocs(nextQuery);
    const companyList = companySnapshot.docs.map((doc) => ({
      ...doc.data(), // すべてのフィールドを取得
    }));
    const lastVisible = companySnapshot.docs[companySnapshot.docs.length - 1];
    return { errMsg, companyList, lastVisible };
  } else {
    errMsg = "適切な権限がありません。";
    return { errMsg, companyList: [], lastVisible: [] };
  }
}

//就活機能に登録している学生一覧を取得する関数
export async function getJobStudentList(
  uid: string,
  university: number
): Promise<{
  errMsg: string;
  circleList: any[];
  lastVisible: any;
  totalCount: number;
}> {
  let errMsg = "";
  const superuserDoc = doc(db, "OEMsuperuser", uid);
  const superuserSnapshot = await getDoc(superuserDoc);

  if (
    superuserSnapshot.exists() &&
    superuserSnapshot.data().university.includes(university)
  ) {
    const totalQ = query(
      collection(db, "jobHuntingStudentUsersPrivate"),
      where("university", "==", university),
      orderBy("updatedAt", "desc")
    );
    const q = query(
      collection(db, "jobHuntingStudentUsersPrivate"),
      where("university", "==", university),
      orderBy("updatedAt", "desc"),
      limit(10)
    );
    const totalCount = await getCountFromServer(totalQ);
    const circleSnapshot = await getDocs(q);
    const circleList = circleSnapshot.docs.map((doc) => ({
      ...doc.data(), // すべてのフィールドを取得
    }));
    const lastVisible = circleSnapshot.docs[circleSnapshot.docs.length - 1];
    return {
      errMsg,
      circleList,
      lastVisible,
      totalCount: totalCount.data().count,
    };
  } else {
    errMsg = "適切な権限がありません。";
    return { errMsg, circleList: [], lastVisible: [], totalCount: 0 };
  }
}

//追加で会社を取得する関数（最後のドキュメントスナップショットを渡す）
export async function getAdditionalJobStudentList(
  uid: string,
  university: number,
  lastDocSnapshot: any
): Promise<{ errMsg: string; companyList: any[]; lastVisible: any }> {
  let errMsg = "";
  const superuserDoc = doc(db, "OEMsuperuser", uid);
  const superuserSnapshot = await getDoc(superuserDoc);

  if (
    superuserSnapshot.exists() &&
    superuserSnapshot.data().university.includes(university)
  ) {
    const nextQuery = query(
      collection(db, "jobHuntingStudentUsersPrivate"),
      orderBy("updatedAt", "desc"),
      startAfter(lastDocSnapshot),
      limit(10)
    );
    const companySnapshot = await getDocs(nextQuery);
    const companyList = companySnapshot.docs.map((doc) => ({
      ...doc.data(), // すべてのフィールドを取得
    }));

    const lastVisible = companySnapshot.docs[companySnapshot.docs.length - 1];
    return { errMsg, companyList, lastVisible };
  } else {
    errMsg = "適切な権限がありません。";
    return { errMsg, companyList: [], lastVisible: [] };
  }
}

//就活機能に登録している学生一覧を取得する関数
export async function getRecruitedJobStudentList(
  uid: string,
  university: number
): Promise<{
  errMsg: string;
  offerList: any[];
  lastVisible: any;
  totalCount: number;
}> {
  let errMsg = "";
  const superuserDoc = doc(db, "OEMsuperuser", uid);
  const superuserSnapshot = await getDoc(superuserDoc);

  if (
    superuserSnapshot.exists() &&
    superuserSnapshot.data().university.includes(university)
  ) {
    const totalQ = query(
      collection(db, "applications"),
      where("student.university", "==", university),
      orderBy("updatedAt", "desc")
    );
    const q = query(
      collection(db, "applications"),
      where("student.university", "==", university),
      orderBy("updatedAt", "desc"),
      limit(10)
    );
    const totalCount = await getCountFromServer(totalQ);
    const offerSnapshot = await getDocs(q);

    const offerList = offerSnapshot.docs.map((doc) => ({
      ...doc.data(), // すべてのフィールドを取得
    }));
    const lastVisible = offerSnapshot.docs[offerSnapshot.docs.length - 1];
    return {
      errMsg,
      offerList,
      lastVisible,
      totalCount: totalCount.data().count,
    };
  } else {
    errMsg = "適切な権限がありません。";
    return { errMsg, offerList: [], lastVisible: [], totalCount: 0 };
  }
}

//追加で会社を取得する関数（最後のドキュメントスナップショットを渡す）
export async function getAdditionalRecruitedJobStudentList(
  uid: string,
  university: number,
  lastDocSnapshot: any
): Promise<{ errMsg: string; offerList: any[]; lastVisible: any }> {
  let errMsg = "";
  const superuserDoc = doc(db, "OEMsuperuser", uid);
  const superuserSnapshot = await getDoc(superuserDoc);

  if (
    superuserSnapshot.exists() &&
    superuserSnapshot.data().university.includes(university)
  ) {
    const nextQuery = query(
      collection(db, "applications"),
      where("student.university", "==", university),
      orderBy("updatedAt", "desc"),
      startAfter(lastDocSnapshot),
      limit(10)
    );

    const companySnapshot = await getDocs(nextQuery);
    const offerList = companySnapshot.docs.map((doc) => ({
      ...doc.data(), // すべてのフィールドを取得
    }));

    const lastVisible = companySnapshot.docs[companySnapshot.docs.length - 1];
    return { errMsg, offerList, lastVisible };
  } else {
    errMsg = "適切な権限がありません。";
    return { errMsg, offerList: [], lastVisible: [] };
  }
}

// 大学のクーポン一覧を取得する関数
export async function getOpenCouponList(university: number): Promise<{
  errMsg: string;
  couponLists: Array<DocumentData>;
  lastVisible: QueryDocumentSnapshot<DocumentData> | null;
}> {
  let errMsg = "";
  const couponLists: Array<DocumentData> = [];
  let lastVisible: QueryDocumentSnapshot<DocumentData> | null = null;

  try {
    const q = query(
      collection(db, "oemCoupons"),
      where("university", "==", university),
      orderBy("createdAt", "desc"),
      where("isOpen", "==", true),
      limit(10)
    );
    const coupons = await getDocs(q);

    if (!coupons.empty) {
      coupons.docs.forEach((doc) => {
        couponLists.push({
          id: doc.id,
          ...doc.data(), // すべてのフィールドを取得
        });
      });
      lastVisible = coupons.docs[coupons.docs.length - 1];
    }
  } catch (error) {
    errMsg = "クーポンの取得に失敗しました。";
    console.error(errMsg, error); // エラーログの改善
  }

  return { errMsg, couponLists, lastVisible };
}

// 大学のクーポン一覧を追加で取得する関数
export async function getExtraOpenCouponList(
  university: number,
  lastSnapshot: DocumentSnapshot
): Promise<{
  errMsg: string;
  couponLists: Array<DocumentData>;
  lastVisible: QueryDocumentSnapshot<DocumentData> | null;
}> {
  let errMsg = "";
  const couponLists: Array<DocumentData> = [];
  let lastVisible: QueryDocumentSnapshot<DocumentData> | null = null;

  try {
    const q = query(
      collection(db, "oemCoupons"),
      where("university", "==", university),
      orderBy("createdAt", "desc"),
      where("isOpen", "==", true),
      limit(10),
      startAfter(lastSnapshot)
    );
    const coupons = await getDocs(q);

    if (!coupons.empty) {
      coupons.docs.forEach((doc) => {
        couponLists.push({
          id: doc.id,
          ...doc.data(), // すべてのフィールドを取得
        });
      });
      lastVisible = coupons.docs[coupons.docs.length - 1];
    }
  } catch (error) {
    errMsg = "クーポンの取得に失敗しました。";
    console.error(errMsg, error); // エラーログの改善
  }

  return { errMsg, couponLists, lastVisible };
}
export async function getStudentsByEmail(
  uid: string,
  university: number,
  email: string
): Promise<{
  errMsg: string;
  studentData: any[];
}> {
  let errMsg = "";
  const superuserDoc = doc(db, "OEMsuperuser", uid);
  const superuserSnapshot = await getDoc(superuserDoc);
  // 適切な権限があるか確認
  if (
    superuserSnapshot.exists() &&
    superuserSnapshot.data().university.includes(university)
  ) {
    const q = query(
      collection(db, "studentUsersPrivate"),
      where("university", "==", university),
      where("address", "==", email)
    );
    const studentSnapshot = await getDocs(q);
    if (!studentSnapshot.empty) {
      const studentData = studentSnapshot.docs.map((doc) => doc.data());
      return {
        errMsg,
        studentData,
      };
    } else {
      errMsg = "該当する生徒が見つかりません。";
      return { errMsg, studentData: [] };
    }
  } else {
    errMsg = "適切な権限がありません。";
    return { errMsg, studentData: [] };
  }
}
// 大学のクーポン一覧を取得する関数
export async function getClosedCouponList(university: number): Promise<{
  errMsg: string;
  couponLists: Array<DocumentData>;
  lastVisible: QueryDocumentSnapshot<DocumentData> | null;
}> {
  let errMsg = "";
  const couponLists: Array<DocumentData> = [];
  let lastVisible: QueryDocumentSnapshot<DocumentData> | null = null;

  try {
    const q = query(
      collection(db, "oemCoupons"),
      where("university", "==", university),
      orderBy("createdAt", "desc"),
      where("isOpen", "==", false),
      limit(10)
    );
    const coupons = await getDocs(q);

    if (!coupons.empty) {
      coupons.docs.forEach((doc) => {
        couponLists.push({
          id: doc.id,
          ...doc.data(), // すべてのフィールドを取得
        });
      });
      lastVisible = coupons.docs[coupons.docs.length - 1];
    }
  } catch (error) {
    errMsg = "クーポンの取得に失敗しました。";
    console.error(errMsg, error); // エラーログの改善
  }

  return { errMsg, couponLists, lastVisible };
}

// 大学の非公開クーポン一覧を追加で取得する関数
export async function getExtraClosedCouponList(
  university: number,
  lastSnapshot: DocumentSnapshot
): Promise<{
  errMsg: string;
  couponLists: Array<DocumentData>;
  lastVisible: QueryDocumentSnapshot<DocumentData> | null;
}> {
  let errMsg = "";
  const couponLists: Array<DocumentData> = [];
  let lastVisible: QueryDocumentSnapshot<DocumentData> | null = null;

  try {
    const q = query(
      collection(db, "oemCoupons"),
      where("university", "==", university),
      orderBy("createdAt", "desc"),
      where("isOpen", "==", false),
      limit(10),
      startAfter(lastSnapshot)
    );
    const coupons = await getDocs(q);

    if (!coupons.empty) {
      coupons.docs.forEach((doc) => {
        couponLists.push({
          id: doc.id,
          ...doc.data(), // すべてのフィールドを取得
        });
      });
      lastVisible = coupons.docs[coupons.docs.length - 1];
    }
  } catch (error) {
    errMsg = "クーポンの取得に失敗しました。";
    console.error(errMsg, error); // エラーログの改善
  }

  return { errMsg, couponLists, lastVisible };
}

//クーポンを登録する関数
export async function registerCoupon(
  uid: string,
  university: number,
  couponPht: Blob,
  isOpen: boolean,
  googleMapUrl: string,
  isAffiliate: boolean,
  affiliateCode: string,
  serviceUrl: string,
  onlyOnce: boolean,
  place: string
) {
  let errMsg = "";
  const superuserDoc = doc(db, "OEMsuperuser", uid);

  const superuserSnapshot = await getDoc(superuserDoc);
  let imgUrl = "";
  if (
    superuserSnapshot.exists() &&
    superuserSnapshot.data().university.includes(university)
  ) {
    const time = new Date();
    let id = time
      .toISOString()
      .replace(/[^0-9]/g, "")
      .substring(0, 17);
    const imgRef: StorageReference = ref(storage, `oemPhts/${uid}/${id}`);
    imgUrl = imgRef.fullPath;
    const metadata = { contentType: "image/jpeg" };
    await uploadBytes(imgRef, couponPht, metadata);
    if (isAffiliate) {
      var couponData: oemCoupons = {
        isAffiliate: isAffiliate,
        university: university,
        imgRef: imgUrl,
        isOpen: isOpen,
        mapurl: "",
        affiliateCode: affiliateCode,
        serviceUrl: serviceUrl,
        onlyOnce: onlyOnce,
        usedBy: [],
        place: place,
        createdAt: serverTimestamp(),
      };
    } else {
      var couponData: oemCoupons = {
        isAffiliate: isAffiliate,
        university: university,
        imgRef: imgUrl,
        isOpen: isOpen,
        mapurl: googleMapUrl,
        affiliateCode: "",
        serviceUrl: "",
        onlyOnce: onlyOnce,
        usedBy: [],
        place: place,
        createdAt: serverTimestamp(),
      };
    }
    const couponCol = collection(db, "oemCoupons");
    await addDoc(couponCol, couponData);
    return errMsg;
  } else {
    errMsg = "適切な権限がありません。";
    return errMsg;
  }
}

//クーポンを削除する関数
//クーポンを削除する関数
export async function deleteCoupon(
  uid: string,
  university: number,
  couponId: string
) {
  let errMsg = "";
  try {
    const superuserDoc = doc(db, "OEMsuperuser", uid);
    const superuserSnapshot = await getDoc(superuserDoc);
    // 適切な権限があるかを確認
    if (
      superuserSnapshot.exists() &&
      superuserSnapshot.data().university.includes(university)
    ) {
      //クーポンを取得
      const couponDoc = doc(db, "oemCoupons", couponId);
      if (!(await getDoc(couponDoc)).exists()) {
        errMsg = "指定したクーポンが存在しません。";
        return errMsg;
      }
      await deleteDoc(couponDoc);
    } else {
      errMsg = "適切な権限がありません。";
    }
  } catch (error) {
    console.error("クーポン削除中にエラーが発生しました: ", error);
    errMsg = "クーポンの削除中にエラーが発生しました。";
  }
  return errMsg;
}
export async function fetchUniversitiesForUser(uid: string): Promise<string[]> {
  const superuserDocRef = doc(db, "OEMsuperuser", uid);
  const superuserDocSnap = await getDoc(superuserDocRef);
  if (superuserDocSnap.exists()) {
    const userData = superuserDocSnap.data();
    return userData.university || [];
  } else {
    console.log("No such document!");
    return [];
  }
}
// クーポンを公開する関数
export async function openCoupon(
  uid: string,
  university: number,
  couponId: string
) {
  let errMsg = "";
  const superuserDoc = doc(db, "OEMsuperuser", uid);
  const superuserSnapshot = await getDoc(superuserDoc);
  // 適切な権限があるかを確認
  if (
    superuserSnapshot.exists() &&
    superuserSnapshot.data().university.includes(university)
  ) {
    //クーポンを取得
    const couponDoc = doc(db, "oemCoupons", couponId);
    if (!(await getDoc(couponDoc)).exists()) {
      errMsg = "指定したクーポンが存在しません。";
      return errMsg;
    }
    await updateDoc(couponDoc, { isOpen: true });
    return errMsg;
  } else {
    errMsg = "適切な権限がありません。";
    return errMsg;
  }
}

// クーポンを非公開にする関数
export async function closeCoupon(
  uid: string,
  university: number,
  couponId: string
) {
  let errMsg = "";
  const superuserDoc = doc(db, "OEMsuperuser", uid);
  const superuserSnapshot = await getDoc(superuserDoc);
  // 適切な権限があるかを確認
  if (
    superuserSnapshot.exists() &&
    superuserSnapshot.data().university.includes(university)
  ) {
    //クーポンを取得
    const couponDoc = doc(db, "oemCoupons", couponId);
    if (!(await getDoc(couponDoc)).exists()) {
      errMsg = "指定したクーポンが存在しません。";
      return errMsg;
    }
    await updateDoc(couponDoc, { isOpen: false });
    return errMsg;
  } else {
    errMsg = "適切な権限がありません。";
    return errMsg;
  }
}

// クーポンの使用回数を取得する関数
export async function getCouponUsageCount(
  uid: string,
  university: number,
  couponId: string
): Promise<{ errMsg: string; usageCount: number }> {
  let errMsg = "";
  let usageCount = 0;
  const superuserDoc = doc(db, "OEMsuperuser", uid);
  const superuserSnapshot = await getDoc(superuserDoc);
  // 適切な権限があるかを確認
  if (
    superuserSnapshot.exists() &&
    superuserSnapshot.data().university.includes(university)
  ) {
    //クーポンを取得
    const couponDoc = doc(db, "oemCoupons", couponId);
    const couponSnapshot = await getDoc(couponDoc);
    if (!couponSnapshot.exists()) {
      errMsg = "指定したクーポンが存在しません。";
      return { errMsg, usageCount };
    }
    const couponData = couponSnapshot.data();
    usageCount = couponData.usedBy ? couponData.usedBy.length : 0;
    return { errMsg, usageCount };
  } else {
    errMsg = "適切な権限がありません。";
    return { errMsg, usageCount };
  }
}

// 通知を送信する関数
export async function sendNotification(
  uid: string,
  university: number,
  messageBody: string,
  title: string
) {
  console.log("sendNotification");

  let errMsg = "";
  try {
    const superuserDoc = doc(db, "OEMsuperuser", uid);
    const superuserSnapshot = await getDoc(superuserDoc);
    const pushTokens: string[] = [];
    if (
      superuserSnapshot.exists() &&
      superuserSnapshot.data().university.includes(university)
    ) {
      const q = query(
        collection(db, "studentUsersPrivate"),
        where("university", "==", university)
      );
      const stdSnapshot = await getDocs(q);
      stdSnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.pushToken) {
          data.pushToken.forEach((token: any) => {
            pushTokens.push(token);
          });
        }
      });
    }
    const noticeData = {
      title: title,
      messageBody: messageBody,
      pushTokens: pushTokens,
      sentBy: uid,
      sentAt: serverTimestamp(),
      university: university,
      status: "beforeSend",
    };
    const noticePublicData = {
      title: title,
      messageBody: messageBody,
      sentAt: serverTimestamp(),
      university: university,
    };
    await addDoc(collection(db, "oemGroupNotification"), noticeData);
    await addDoc(collection(db, "notificationPublic"), noticePublicData);
  } catch (error) {
    errMsg = error instanceof Error ? error.message : "エラーが発生しました。";
  }
  return errMsg;
}
// 通知を送信する関数
export async function sendNotificationForPublic(
  uid: string,
  university: number,
  messageBody: string,
  title: string
) {
  console.log("public");

  let errMsg = "";
  try {
    const superuserDoc = doc(db, "OEMsuperuser", uid);
    const superuserSnapshot = await getDoc(superuserDoc);
    const pushTokens: string[] = [];
    if (
      superuserSnapshot.exists() &&
      superuserSnapshot.data().university.includes(university)
    ) {
      const q = query(
        collection(db, "studentUsersPrivate"),
        where("university", "==", university)
      );
      const stdSnapshot = await getDocs(q);
      stdSnapshot.forEach((doc) => {
        const data = doc.data();
        if (data.pushToken) {
          data.pushToken.forEach((token: any) => {
            pushTokens.push(token);
          });
        }
      });
    }
    const noticePublicData = {
      title: title,
      messageBody: messageBody,
      sentAt: serverTimestamp(),
      university: university,
    };
    await addDoc(collection(db, "notificationPublic"), noticePublicData);
  } catch (error) {
    errMsg = error instanceof Error ? error.message : "エラーが発生しました。";
  }
  return errMsg;
}

//企業のデータを取得する関数
export async function getCompanyDetail(
  uid: string,
  companyId: string,
  university: number
): Promise<{
  compData: any | null;
  ongoingOfferSize: number | null;
  sponsorNum: number | null;
  openOfferTmpSize: number | null;
  offerTmpSize: number | null;
  monthlyOfferNum: number | null;
  lastLogin: FieldValue | null;
  offerChatList: any | null;
  recruitChatList: any | null;
  offerTmp: any | null;
}> {
  try {
    const superuserDoc = doc(db, "OEMsuperuser", uid);
    const superuserSnapshot = await getDoc(superuserDoc);
    if (
      !(
        superuserSnapshot.exists() &&
        superuserSnapshot.data().university.includes(university)
      )
    ) {
      console.log("権限が存在しません。");
      return {
        compData: null,
        ongoingOfferSize: null,
        sponsorNum: null,
        openOfferTmpSize: null,
        offerTmpSize: null,
        monthlyOfferNum: null,
        lastLogin: null,
        offerChatList: null,
        recruitChatList: null,
        offerTmp: null,
      };
    }
    const companyDoc = doc(db, "compUsersPrivate", companyId);
    const companySnapshot = await getDoc(companyDoc);
    if (!companySnapshot.exists()) {
      console.log("指定した企業が見つかりません。");
      return {
        compData: null,
        ongoingOfferSize: null,
        sponsorNum: null,
        openOfferTmpSize: null,
        offerTmpSize: null,
        monthlyOfferNum: null,
        lastLogin: null,
        offerChatList: null,
        recruitChatList: null,
        offerTmp: null,
      };
    }
    const compData = companySnapshot.data();
    // オファー(求人)のテンプレートの数を取得
    const offerTmpQuery = query(
      collection(db, "compUsersPrivate", companyId, "offerTemplates"),
      orderBy("updatedAt", "desc")
    );
    const offerTmp = await getDocs(offerTmpQuery); //後でオファー情報として使用
    const offerTmpSize = offerTmp.size; //作成した求人(オファー)の数
    // 募集中の求人のテンプレートの数を取得
    const openOfferQuery = query(
      collection(db, "compUsersPrivate", companyId, "offerTemplates"),
      where("isUseForApplications", "==", true)
    );
    const openOfferTmp = await getDocs(openOfferQuery);
    const openOfferTmpSize = openOfferTmp.size;
    //実際に進行中のオファーの取得
    const offerQuery = query(
      collection(db, "offers"),
      where("company.ref", "==", companyDoc),
      where("status", "==", "accepted")
    );
    const offerSnapshot = await getDocs(offerQuery);
    const ongoingOfferSize = offerSnapshot.size;
    // 指定の大学の学生に送信したオファーを取得
    const univOfferQuery = query(
      collection(db, "offers"),
      where("company.ref", "==", companyDoc),
      where("student.university", "==", university),
      orderBy("updatedAt", "desc")
    );
    const univOfferSnapshot = await getDocs(univOfferQuery);
    // 実際に進行中の求人(学生から応募)の取得
    const applicationQuery = query(
      collection(db, "applications"),
      where("company.ref", "==", companyDoc)
    );
    const applicationSnapshot = await getDocs(applicationQuery);
    const applicationSize = applicationSnapshot.size;
    // オファー+求人で学生の選考の数を取得
    const selectionNum = ongoingOfferSize + applicationSize;
    // 指定の大学の学生から応募されてきたオファーを取得
    const univApplicationQuery = query(
      collection(db, "applications"),
      where("company.ref", "==", companyDoc),
      where("student.university", "==", university),
      orderBy("updatedAt", "desc")
    );
    const univApplicationSnapshot = await getDocs(univApplicationQuery);
    // 今月送信したオファー数の取得
    const now = new Date();
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
    // 現在の月の最後の日を取得
    const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    // Firestore クエリ
    const monthlyOfferQuery = query(
      collection(db, "offers"),
      where("company.ref", "==", companyDoc),
      where("yourDateField", ">=", startOfMonth),
      where("yourDateField", "<=", endOfMonth)
    );
    const monthlyOfferSnapshot = await getDocs(monthlyOfferQuery);
    const monthlyOfferSize = monthlyOfferSnapshot.size;
    return {
      compData: compData,
      ongoingOfferSize: selectionNum,
      sponsorNum: 0,
      openOfferTmpSize: openOfferTmpSize,
      offerTmpSize: offerTmpSize,
      monthlyOfferNum: monthlyOfferSize,
      offerChatList: univOfferSnapshot,
      recruitChatList: univApplicationSnapshot,
      offerTmp: offerTmp,
      lastLogin: null,
    };
  } catch (error) {
    // エラーハンドリング
    console.error(`エラーが発生しました。: `, error);
    return {
      compData: null,
      ongoingOfferSize: null,
      sponsorNum: null,
      openOfferTmpSize: null,
      offerTmpSize: null,
      monthlyOfferNum: null,
      lastLogin: null,
      offerChatList: null,
      recruitChatList: null,
      offerTmp: null,
    };
  }
}

// 学生のデータを取得する関数
export async function getStudentDetail(
  uid: string,
  studentId: string,
  university: number
): Promise<{
  stdData: any | null;
  jobStdData: any | null;
  ongolingAppliNum: number | null;
  offerNum: number | null;
  applChatList: any | null;
  offerChatList: any | null;
  lastLogin: FieldValue | null;
}> {
  try {
    const superuserDoc = doc(db, "OEMsuperuser", uid);
    const superuserSnapshot = await getDoc(superuserDoc);
    if (
      !(
        superuserSnapshot.exists() &&
        superuserSnapshot.data().university.includes(university)
      )
    ) {
      console.log("権限が存在しません。");
      return {
        stdData: null,
        jobStdData: null,
        ongolingAppliNum: null,
        offerNum: null,
        applChatList: null,
        offerChatList: null,
        lastLogin: null,
      };
    }
    const stdDoc = doc(db, "studentUsersPrivate", studentId);
    const stdSnapshot = await getDoc(stdDoc);
    if (!stdSnapshot.exists()) {
      // クラブが存在しない場合の処理
      console.log(`指定した学生が見つかりませんでした。`);
      return {
        stdData: null,
        jobStdData: null,
        ongolingAppliNum: null,
        offerNum: null,
        applChatList: null,
        offerChatList: null,
        lastLogin: null,
      };
    }
    const stdData = stdSnapshot.data();
    // 就活用データが存在するか確認
    const jobStdDoc = doc(db, "jobHuntingStudentUsersPrivate", studentId);
    const jobStdSnapshot = await getDoc(jobStdDoc);
    if (!jobStdSnapshot) {
      //就活機能に登録していない場合は通常ユーザー情報のみを返す
      return {
        stdData,
        jobStdData: null,
        ongolingAppliNum: 0,
        offerNum: 0,
        applChatList: null,
        offerChatList: null,
        lastLogin: null,
      };
    }
    //就活機能に登録している場合はデータを取得して返す
    // 自分から応募したものの取得
    const applQuery = query(
      collection(db, "applications"),
      where("student.id", "==", studentId),
      orderBy("updatedAt", "desc")
    );
    const applSnapshot = await getDocs(applQuery);
    // 受けたオファーの取得
    const offerQuery = query(
      collection(db, "offers"),
      where("student.id", "==", studentId),
      orderBy("updatedAt", "desc")
    );
    const offerSnapshot = await getDocs(offerQuery);
    return {
      stdData,
      jobStdData: jobStdSnapshot.data(),
      ongolingAppliNum: applSnapshot.size,
      offerNum: offerSnapshot.size,
      applChatList: applSnapshot,
      offerChatList: offerSnapshot,
      lastLogin: null,
    };
  } catch (error) {
    // エラーハンドリング
    console.error(`エラーが発生しました。: `, error);
    return {
      stdData: null,
      jobStdData: null,
      ongolingAppliNum: null,
      offerNum: null,
      applChatList: null,
      offerChatList: null,
      lastLogin: null,
    };
  }
}

// サークルのデータを取得する関数
export async function getClubDetail(
  uid: string,
  clubId: string,
  university: number
): Promise<{
  clubBasicData: any | null;
  sponsorCompNum: number | null;
  lastLogin: FieldValue | null;
  chatList: any | null;
}> {
  try {
    const superuserDoc = doc(db, "OEMsuperuser", uid);
    const superuserSnapshot = await getDoc(superuserDoc);
    if (
      !(
        superuserSnapshot.exists() &&
        superuserSnapshot.data().university.includes(university)
      )
    ) {
      console.log("権限が存在しません。");
      return {
        clubBasicData: null,
        sponsorCompNum: null,
        lastLogin: null,
        chatList: null,
      };
    }
    const clubDoc = doc(db, "clubUsersPrivate", clubId);
    const clubSnapshot = await getDoc(clubDoc);
    if (!clubSnapshot.exists()) {
      // クラブが存在しない場合の処理
      console.log(`指定したサークルが見つかりませんでした。`);
      return {
        clubBasicData: null,
        sponsorCompNum: null,
        lastLogin: null,
        chatList: null,
      };
    }
    const clubBasicData = clubSnapshot.data();
    //チャットを取得
    const chatCol = collection(db, "clubUsersPublic", clubId, "chats");
    const chatQuery = query(chatCol, orderBy("updatedAt", "desc"));
    const chatList = await getDocs(chatQuery);
    return {
      clubBasicData,
      sponsorCompNum: 0,
      lastLogin: serverTimestamp(),
      chatList: chatList,
    };
  } catch (error) {
    // エラーハンドリング
    console.error(`エラーが発生しました。: `, error);
    return {
      clubBasicData: null,
      sponsorCompNum: null,
      lastLogin: null,
      chatList: null,
    };
  }
}

export async function registerOemEventInfo(
  uid: string,
  university: number,
  thumbnailImg: Blob,
  contentImg: Blob,
  titleText: string,
  linkText: string,
  isPublished: boolean
) {
  let errMsg = "";
  const superuserDoc = doc(db, "OEMsuperuser", uid);

  const superuserSnapshot = await getDoc(superuserDoc);
  if (
    superuserSnapshot.exists() &&
    superuserSnapshot.data().university.includes(university)
  ) {
    const time = new Date();
    const id1 =
      time
        .toISOString()
        .replace(/[^0-9]/g, "")
        .substring(0, 17) + "_1";
    const id2 =
      time
        .toISOString()
        .replace(/[^0-9]/g, "")
        .substring(0, 17) + "_2";

    const thumbnailImgRef: StorageReference = ref(
      storage,
      `oemEventPhts/${uid}/${id1}`
    );
    const contentImgRef: StorageReference = ref(
      storage,
      `oemEventPhts/${uid}/${id2}`
    );

    const thumbnailImgUrl = thumbnailImgRef.fullPath;
    const contentImgUrl = contentImgRef.fullPath;

    const metadata = { contentType: "image/jpeg, image/png" };
    await uploadBytes(thumbnailImgRef, thumbnailImg, metadata);
    await uploadBytes(contentImgRef, contentImg, metadata);

    const dataToSave = {
      university: university,
      thumbnailImg: thumbnailImgUrl,
      contentImg: contentImgUrl,
      title: titleText,
      link: linkText,
      isPublished: isPublished,
      createdAt: serverTimestamp(),
    };

    const dataCollection = collection(db, "oemEvents");
    await addDoc(dataCollection, dataToSave);
    return errMsg;
  } else {
    errMsg = "適切な権限がありません。";
    return errMsg;
  }
}

// export async function deleteCircleAndEvents(
//   uid: string,
//   university: number,
//   circleId: string
// ): Promise<string> {
//   let errMsg = "";
//   const superuserDoc = doc(db, "OEMsuperuser", uid);
//   const superuserSnapshot = await getDoc(superuserDoc);
//   // 適切な権限があるかを確認
//   if (
//     superuserSnapshot.exists() &&
//     superuserSnapshot.data().university.includes(university)
//   ) {
//     // サークルのプライベート情報を削除
//     const circlePrivateDoc = doc(db, "clubUsersPrivate", circleId);
//     if (!(await getDoc(circlePrivateDoc)).exists()) {
//       errMsg = "指定したサークルが存在しません。";
//       return errMsg;
//     }

//     // サークルが持っているイベントの一覧を取得
//     const eventsCollectionRef = collection(db, "clubUsersPrivate", circleId, "events");
//     const eventsSnapshot = await getDocs(eventsCollectionRef);

//     const batch = writeBatch(db);

//     // サークルのイベントを削除
//     eventsSnapshot.docs.forEach((eventDoc) => {
//       const eventId = eventDoc.id;
//       batch.delete(doc(db, "clubEvents", eventId)); // グローバルなイベント情報を削除
//       batch.delete(doc(db, "clubUsersPrivate", circleId, "events", eventId)); // サークル内のイベント情報を削除
//     });

//     // サークルのプライベート情報と公開情報を削除
//     batch.delete(circlePrivateDoc);
//     const circlePublicDoc = doc(db, "clubUsersPublic", circleId);
//     batch.delete(circlePublicDoc);

//     // バッチ処理を実行
//     await batch.commit().catch((error) => {
//       console.error("サークルとイベントの削除中にエラーが発生しました: ", error);
//       errMsg = "サークルとイベントの削除中にエラーが発生しました。";
//     });
//   } else {
//     errMsg = "適切な権限がありません。";
//   }
//   return errMsg;
// }
